import React from 'react'

const FollowBell = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='30' height='32' viewBox='0 0 30 32' fill='none'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M15.112 28.638C23.0201 28.638 29.4309 22.2271 29.4309 14.319C29.4309 6.41083 23.0201 0 15.112 0C7.2038 0 0.792969 6.41083 0.792969 14.319C0.792969 18.7718 2.82551 22.75 6.01343 25.3762C6.01922 25.381 6.01406 25.3902 6.00696 25.3878C6.00259 25.3863 5.99805 25.3896 5.99805 25.3942V29.2963C5.99805 30.786 7.56814 31.7528 8.89832 31.0823L13.3477 28.8394C13.6794 28.6721 14.051 28.604 14.4221 28.6216C14.6507 28.6325 14.8807 28.638 15.112 28.638Z'
        fill='#005E38'
      />
      <g transform={`translate(7, 4)`}>
        <svg xmlns='http://www.w3.org/2000/svg' width='15' height='18' viewBox='0 0 15 18' fill='none'>
          <path
            d='M10.4738 15.3194C10.4738 15.9309 10.2309 16.5174 9.79846 16.9498C9.36605 17.3822 8.77957 17.6252 8.16805 17.6252C7.55653 17.6252 6.97005 17.3822 6.53764 16.9498C6.10523 16.5174 5.8623 15.9309 5.8623 15.3194L8.16805 15.3194H10.4738Z'
            fill='white'
          />
          <path
            d='M3.19705 7.58894C3.19705 4.93696 5.3469 2.78711 7.99888 2.78711V2.78711C10.6509 2.78711 12.8007 4.93696 12.8007 7.58894V8.45298V9.37051C12.8007 10.6035 13.3213 11.7548 13.9497 12.8157C14.3279 13.4543 14.5094 14.0627 13.8783 14.1189C12.8243 14.2127 5.6396 14.1618 2.40574 14.1336C1.77526 14.1281 1.43965 13.3928 1.84352 12.9086L2.0401 12.6729C2.78762 11.7768 3.19705 10.6467 3.19705 9.47975V8.45298V7.58894Z'
            stroke='white'
            stroke-width='1.03372'
            stroke-linejoin='round'
          />
          <path
            d='M8.54665 1.19727C8.54665 0.911812 8.31524 0.680406 8.02979 0.680406C7.74433 0.680406 7.51293 0.911812 7.51293 1.19727H8.54665ZM7.51293 1.19727V2.53379H8.54665V1.19727H7.51293Z'
            fill='white'
          />
        </svg>
      </g>
    </svg>
  )
}

export default FollowBell
