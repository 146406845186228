
import styles from './style.module.scss'
import FollowBell from '@/assets/icons/FollowBell'
import IconPopupError from '@/assets/icons/IconPopupError'
import IconPopupWarning from '@/assets/icons/IconPopupWarning'
import IconPopupTimeExtend from '@/assets/icons/IconPopupTimeExtend'
import IconPopupSuccess from '@/assets/icons/IconPopupSuccess'
import IconNewBidRevamp from '@/assets/icons/IconNewBidRevamp'

/**
 *
 * @param {*} showIcon Will support only 4 text i.e success, warning, error, newBid
 * @param {*} headerText Heading text to be shown in bold
 * @param {*} descriptiveText Text to be shown below heading
 * @param {*} onClose Function to be called on cross icon clicked or popup closed after few seconds
 */
const CustomToast = ({
  customTick = false,
  showIcon,
  headerText = '',
  descriptiveText = '',
  isSingle = true,
  id = 'toastPopup',
}) => {
  const Icons = {
    success: <IconPopupSuccess customTick={customTick}/>,
    warning: <IconPopupWarning />,
    error: <IconPopupError />,
    new_bid: <IconNewBidRevamp />,
    time_extend: <IconPopupTimeExtend />,
    follow_bell: <FollowBell />
  }
  return (
    <>
      <div
        id={id}
        className={`${isSingle ? styles.toastWrapWhenSingle : styles.toastWrap} ${isSingle && styles.singlePopup}`}
      >
        <div id={`toast_${id}`} className={`${styles.toastPopup} ${styles[showIcon]}`}>
          <span className={styles.icon}>{Icons[showIcon]}</span>
          <div className={styles.popupText}>
            <div
              className={`${styles.headerText} ${
                (showIcon == 'time_extend' || showIcon == 'warning') && styles.color_black
              }`}
            >
              {headerText}
            </div>
            <div
              className={`${styles.descriptiveText} ${
                (showIcon == 'time_extend' || showIcon == 'warning') && styles.color_black
              }`}
            >
              {descriptiveText}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CustomToast
