import React from 'react'

const IconPopupError = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M24.0001 42.24C35.6644 42.24 45.1201 32.7843 45.1201 21.12C45.1201 9.45575 35.6644 0 24.0001 0C12.3359 0 2.88013 9.45575 2.88013 21.12C2.88013 27.6878 5.87802 33.5553 10.58 37.4289C10.5875 37.4351 10.5809 37.4471 10.5717 37.444C10.566 37.442 10.5601 37.4462 10.5601 37.4522V44.7521C10.5601 46.2417 12.1302 47.2085 13.4604 46.538L21.6384 42.4155C21.9702 42.2483 22.3418 42.1791 22.7126 42.2014C23.1385 42.227 23.5678 42.24 24.0001 42.24Z" fill="#99004D"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8691 22.5652C20.8691 24.222 22.2123 25.5652 23.8691 25.5652H24.13C25.7869 25.5652 27.13 24.222 27.13 22.5652L27.13 9.08691C27.13 7.43006 25.7869 6.08691 24.13 6.08691H23.8691C22.2123 6.08691 20.8691 7.43006 20.8691 9.08691V22.5652ZM23.9996 33.913C25.7285 33.913 27.13 32.5115 27.13 30.7826C27.13 29.0537 25.7285 27.6521 23.9996 27.6521C22.2707 27.6521 20.8691 29.0537 20.8691 30.7826C20.8691 32.5115 22.2707 33.913 23.9996 33.913Z" fill="white"/>
    </svg>
  )
}

export default IconPopupError