import React from 'react'

const IconPopupWarning = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M24 42.24C35.6643 42.24 45.12 32.7843 45.12 21.12C45.12 9.45575 35.6643 0 24 0C12.3358 0 2.88 9.45575 2.88 21.12C2.88 27.6878 5.87789 33.5553 10.5799 37.4289C10.5874 37.4351 10.5807 37.4471 10.5715 37.444C10.5659 37.442 10.56 37.4462 10.56 37.4522V44.7521C10.56 46.2417 12.1301 47.2085 13.4603 46.538L21.6383 42.4155C21.9701 42.2483 22.3417 42.1791 22.7125 42.2014C23.1384 42.227 23.5677 42.24 24 42.24Z" fill="#B15500"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8691 23.5652C20.8691 25.222 22.2123 26.5652 23.8691 26.5652H24.13C25.7869 26.5652 27.13 25.222 27.13 23.5652L27.13 10.0869C27.13 8.43006 25.7869 7.08691 24.13 7.08691H23.8691C22.2123 7.08691 20.8691 8.43006 20.8691 10.0869V23.5652ZM23.9996 34.913C25.7285 34.913 27.13 33.5115 27.13 31.7826C27.13 30.0537 25.7285 28.6521 23.9996 28.6521C22.2707 28.6521 20.8691 30.0537 20.8691 31.7826C20.8691 33.5115 22.2707 34.913 23.9996 34.913Z" fill="white"/>
    </svg>
  )
}

export default IconPopupWarning