import React from 'react'

const IconPopupTimeExtend = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M24 42.24C35.6643 42.24 45.12 32.7843 45.12 21.12C45.12 9.45575 35.6643 0 24 0C12.3358 0 2.88 9.45575 2.88 21.12C2.88 27.6878 5.87789 33.5553 10.5799 37.4289C10.5874 37.4351 10.5807 37.4471 10.5715 37.444C10.5659 37.442 10.56 37.4462 10.56 37.4522V44.7521C10.56 46.2417 12.1301 47.2085 13.4603 46.538L21.6383 42.4155C21.9701 42.2483 22.3417 42.1791 22.7125 42.2014C23.1384 42.227 23.5677 42.24 24 42.24Z" fill="#B15500"/>
      <path d="M17.8508 15.635C19.4445 14.3581 21.4266 13.6637 23.4688 13.667C25.5938 13.667 27.5468 14.403 29.0868 15.635L30.5398 14.182L31.9538 15.596L30.5008 17.049C31.5585 18.3731 32.2208 19.969 32.4116 21.653C32.6023 23.3369 32.3137 25.0405 31.579 26.5677C30.8443 28.0949 29.6934 29.3836 28.2586 30.2856C26.8238 31.1875 25.1635 31.666 23.4688 31.666C21.774 31.666 20.1137 31.1875 18.6789 30.2856C17.2442 29.3836 16.0932 28.0949 15.3585 26.5677C14.6238 25.0405 14.3352 23.3369 14.526 21.653C14.7167 19.969 15.3791 18.3731 16.4368 17.049L14.9838 15.597L16.3978 14.183L17.8508 15.636V15.635ZM23.4688 29.667C24.388 29.667 25.2983 29.4859 26.1475 29.1341C26.9968 28.7824 27.7685 28.2668 28.4185 27.6167C29.0685 26.9667 29.5841 26.1951 29.9359 25.3458C30.2877 24.4965 30.4688 23.5862 30.4688 22.667C30.4688 21.7477 30.2877 20.8375 29.9359 19.9882C29.5841 19.1389 29.0685 18.3673 28.4185 17.7172C27.7685 17.0672 26.9968 16.5516 26.1475 16.1998C25.2983 15.8481 24.388 15.667 23.4688 15.667C21.6122 15.667 19.8318 16.4045 18.519 17.7172C17.2063 19.03 16.4688 20.8105 16.4688 22.667C16.4688 24.5235 17.2063 26.304 18.519 27.6167C19.8318 28.9295 21.6122 29.667 23.4688 29.667ZM24.4688 21.667H27.4688L22.4688 28.167V23.667H19.4688L24.4688 17.162V21.667ZM19.4688 10.667H27.4688V12.667H19.4688V10.667Z" fill="white"/>
    </svg>
  )
}

export default IconPopupTimeExtend