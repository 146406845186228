import { fetchApi } from '@/utils/apiCall'
import { removeCookies } from '@/utils/utils'
import dayjs from 'dayjs'
import UCookies from 'universal-cookie'

  
//renew the authToken and refresh token and update the local storage and cookie
const refreshExpiredToken = async (authToken,refreshToken) => {
    try{
      const res = await fetch(`/api/generic?apiUrl=${process.env.NEXT_PUBLIC_GATEWAY_URL}user-manager/refresh-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: authToken,
        },
        body: JSON.stringify({
          refreshToken,
        }),
      })
    const response = await res.json();
    if (res.status == 400 || response.statusCode == 401) {
        fetchApi({
          lang,
          baseUrl: 'NEXT_PUBLIC_GATEWAY_URL',
          subUrl: `logout?refresh_token=${refreshToken}`,
        })
        localStorage.removeItem('authToken')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('authStoredDate')
        localStorage.removeItem('phoneNumber')
        localStorage.removeItem('userId')
        removeCookies()
      return
    }  
    localStorage.setItem('authToken', response?.data?.data?.accessToken)
    localStorage.setItem('refreshToken', response?.data?.data?.refreshToken)
    const authStoredDate = dayjs().format()
    localStorage.setItem('authStoredDate', authStoredDate)
    updateCookie(response?.data?.data?.accessToken,response?.data?.data?.refreshToken, authStoredDate)
  } catch (error) {
    console.error("Rotate-tokens api failure", error)
  }
  }

const updateCookie = (token, refreshToken, authStoredDate) => {
    const cookie = new UCookies()
    const domain = location.host
    const cookieOptions = {
      path: '/',
      expires: new Date(new Date().setDate(new Date().getDate() + 60)), // 1 moth expiry added to cookie
    }
    if (domain.split('.').length > 1) {
      const domainParts = domain.split('.')
      domainParts.shift()
      cookieOptions.domain = '.' + domainParts.join('.')
    }
    cookie.set('authToken', token, cookieOptions)
    cookie.set('refreshToken', refreshToken, cookieOptions)
    cookie.set('authStoredDate',authStoredDate, cookieOptions)
    if (domain.match(/[a-z]/i) == null) {
      delete cookieOptions.domain
      cookie.set('authToken', token, cookieOptions)
      cookie.set('refreshToken', refreshToken, cookieOptions)
      cookie.set('authStoredDate',authStoredDate, cookieOptions)
    } else if (domain.split('.').length > 1 && (cookie.get('authToken') == null || cookie.get('authToken') != token)) {
      cookieOptions.domain = '.' + location.host
      cookie.set('authToken', token, cookieOptions)
      cookie.set('refreshToken', refreshToken, cookieOptions)
      cookie.set('authStoredDate',authStoredDate, cookieOptions)
    }
  }

// check if the user is logged in for more than 24 hours
export const authCheck = () => {    
  const authStoredDate = window.localStorage.getItem('authStoredDate')    
  if(authStoredDate && dayjs().diff(dayjs(authStoredDate), 'hours') >= 24) {
    const authToken = window.localStorage.getItem('authToken')     
    const refreshToken = window.localStorage.getItem('refreshToken')
    refreshExpiredToken(authToken,refreshToken)
  }
};