import React from 'react'

const IconNewBidRevamp = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.112 28.638C25.0201 28.638 31.4309 22.2271 31.4309 14.319C31.4309 6.41083 25.0201 0 17.112 0C9.2038 0 2.79297 6.41083 2.79297 14.319C2.79297 18.7718 4.82551 22.75 8.01343 25.3762C8.01922 25.381 8.01406 25.3902 8.00696 25.3878C8.00259 25.3863 7.99805 25.3896 7.99805 25.3942V29.2963C7.99805 30.786 9.56814 31.7528 10.8983 31.0823L15.3477 28.8394C15.6794 28.6721 16.051 28.604 16.4221 28.6216C16.6507 28.6325 16.8807 28.638 17.112 28.638Z" fill="white"/>
      <path d="M17.166 18.6669V19.8336H10.166V18.6669H17.166ZM17.5078 7.40039L22.045 11.9376L21.2202 12.7636L20.6018 12.5571L19.1569 14.0002L22.4568 17.3001L21.632 18.125L18.3327 14.8251L16.9303 16.2274L17.0954 16.8877L16.27 17.7126L11.7328 13.1754L12.5583 12.3506L13.2174 12.5151L16.8889 8.84414L16.683 8.22581L17.5078 7.40039Z" fill="#636569"/>
    </svg>
  )
}

export default IconNewBidRevamp