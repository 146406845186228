import { observerService } from "./observer"

var defaultStore = {
    seen: [],
    contacted: [],
    favourites: []
}
export const projectStoreKey = 'projectStore';

export const initializeStore = () => {
    let localStorageData = getDataFromLocalStorage()
    if (!localStorageData || !localStorageData.seen || !localStorageData.contacted || !localStorageData.favourites) {
        setDataToLocalStorage(defaultStore)
    }
}

export const initializeProjectStore = () => {
    let localStorageData = getDataFromLocalStorage(projectStoreKey)
    if (!localStorageData || !localStorageData.seen || !localStorageData.contacted || !localStorageData.favourites) {
        setDataToLocalStorage(defaultStore, projectStoreKey)
    }
}

export const getDataFromLocalStorage = (key = 'propertyStore') => {
    if (typeof window !== "undefined") {
        return JSON.parse(window.localStorage.getItem(key) || `{}`)
    }
}

const setDataToLocalStorage = (data , key= 'propertyStore') => {
    if (typeof window !== "undefined")
        return window.localStorage.setItem(key, JSON.stringify(data))
}

export const setPropertySeen = (data, key= 'propertyStore') => {
    let store = getDataFromLocalStorage(key)
    if (store.seen.indexOf(data.id) == -1) {
        store.seen.push(data.id)
    }
    setDataToLocalStorage(store, key)
    // passing event to observer
    observerService.handleEvent({type: 'seen', data: store});
}

export const setPropertyContacted = (data, key= 'propertyStore', stopHandleEvent = false) => {
    let store = getDataFromLocalStorage(key)
    if (store.contacted.indexOf(data.id) == -1) {
        store.contacted.push(data.id)
    }
    setDataToLocalStorage(store, key)
    // passing event to observer
    if(stopHandleEvent) return; // Added this condition to address the auto-scrolling issue with the list and premium card.
    observerService.handleEvent({type: 'contacted', data: store});
}

export const setPropertyFavourite = (data, key= 'propertyStore') => {
    let store = getDataFromLocalStorage(key)
    let index = store.favourites.indexOf(data.id);
    if (index == -1) {
        store.favourites.push(data.id)
    } else {
        store.favourites.splice(index, 1)
    }
    setDataToLocalStorage(store, key)
    // passing event to observer
    observerService.handleEvent({type: 'favourite', data: store});
}

export const clearPropertyFavourite = (key= 'propertyStore') => {
    let store = getDataFromLocalStorage(key)
    store.favourites = []
    setDataToLocalStorage(store, key)
}