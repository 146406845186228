import React, { useContext, createContext, useState } from 'react'
import UCookies from 'universal-cookie'
import { Howl } from 'howler'
import { fetchApi } from '@/utils/apiCall'
import useTranslation from 'next-translate/useTranslation'
import { useWallet } from './WalletProvider'

export const DashboardContext = createContext()
export const useDashboard = () => useContext(DashboardContext)

const DashboardProvider = ({ children }) => {
  const { lang } = useTranslation('translations-auction')
  const [counts, setCounts] = useState({
    registerAuctionCount: 0,
    shownInterestCount: 0,
    savedItemsCount: 0,
    userFollowedAuctionCount: 0,
  })
  const [loadUser, setLoadUser] = useState(true)
  const [localUserID, setLocalUserID] = useState('')
  const [userDetails, setUserDetails] = useState(null)
  const [helpNumbers, setHelpNumbers] = useState({
    auctionNumber: '',
  })
  const [requirementCount, setRequirementCount] = useState(null)
  const [mystate, setMystate] = useState('notupdate')
  const alertSound = new Howl({
    src: ['/audio/bell.mp3'],
    html5: true,
  })

  const { checkWalletCreated, setIsWalletCreated } = useWallet()

  const getUserInfo = async () => {
    if (!localStorage.getItem('authToken')) {
      getSavedItemsCount()
      setLoadUser(false)
      setIsWalletCreated(false)
      setUserDetails(null)
      return
    }
    try {
      checkWalletCreated()
      getRequirementsCount()
      const res = await fetch(`${process.env.NEXT_PUBLIC_GATEWAY_URL}${'user/profile'}`, {
        method: 'GET',
        headers: {
          locale: lang,
          Cookie: `i18next=${lang}`,
          authorization: localStorage.getItem('authToken'),
        },
      })
      const response = await res.json()
      let firstName = response?.data?.fullName
      if (firstName) {
        localStorage.setItem('info', JSON.stringify({ firstName: response?.data?.fullName }))
      }
      setLoadUser(false)
      if (!response?.data) {
        localStorage.removeItem('authToken')
        return
      }
      setUserDetails(response.data)
      if (response?.data?.phoneNumber) {
        localStorage.setItem('phoneNumber', response?.data?.phoneNumber)
      }
      if (response.data.nationalityId) {
        await Promise.all([
          getSavedAuctionCount(),
          getshownInterestCount(),
          getRegisterAuctionCount(),
          getUserFollowedAuctionCount(),
        ])
      } else {
        await Promise.all([getSavedAuctionCount(), getshownInterestCount(), getUserFollowedAuctionCount()])
      }
    } catch (error) {
      console.log('error occured', error)
      setLoadUser(false)
    }
  }

  const getshownInterestCount = async () => {
    const res = await fetch(`/api/auction/crud?apiUrl=getAllSavedAuctionsProperties`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        locale: lang,
        authorization: window.localStorage.getItem('authToken') ?? '',
      },
      body: JSON.stringify({
        type: 'auction_registration_open',
        registeredAuction: 'true',
        onlyCount: true,
      }),
    })
    const response = await res.json()
    setCounts((prevCounts) => ({ ...prevCounts, shownInterestCount: response?.data?.data?.auctionsCount }))
  }

  const getRegisterAuctionCount = async () => {
    let objectToSend = {
      viewerType: 'user',
      userId: window.localStorage.getItem('userId'),
      onlyCount: true,
    }
    const res = await fetch(`/api/auction/crud?apiUrl=user-auctions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        locale: lang,
        authorization: window.localStorage.getItem('authToken') ?? '',
      },
      body: JSON.stringify(objectToSend),
    })
    const response = await res.json()
    setCounts((prevCounts) => ({ ...prevCounts, registerAuctionCount: response?.data?.data?.auctionsCount }))
  }
  /***
   * For Logged in User
   */
  const getSavedAuctionCount = async () => {
    const response = await fetchApi({
      lang,
      baseUrl: 'NEXT_PUBLIC_AUCTION_API_URL_V2',
      subUrl: `getAllSavedAuctionsProperties`,
      method: 'POST',
      reqBody: { type: 'saved_auction', onlyCount: true },
    })
    setCounts((prevCounts) => ({ ...prevCounts, savedItemsCount: response?.data?.data?.auctionsCount }))
  }

  const getUserFollowedAuctionCount = async () => {
    try {
      const response = await fetchApi({
        lang,
        baseUrl: 'NEXT_PUBLIC_AUCTION_API_URL_V2',
        subUrl: `getAllSavedAuctionsProperties`,
        method: 'POST',
        reqBody: { type: 'followed_auction', onlyCount: true },
      })
      setCounts((prevCounts) => ({ ...prevCounts, userFollowedAuctionCount: response?.data?.data?.auctionsCount }))
    } catch (error) {
      console.log('error', error)
    }
  }
  /***
   *
   * For Anonymous User
   */
  /***
   *
   * all saved items
   */
  const getSavedItemsCount = async () => {
    await Promise.all([getSavedGuestCount('auction'), getSavedGuestCount('property')])
    setCounts((prevCounts) => ({ ...prevCounts, savedItemsCount: prevCounts?.savedAuctionCount + prevCounts?.savedPropertyCount }))
  }

  const getSavedGuestCount = async (type) => {
    let body = {}
    if (type === 'auction') {
      body = {
        type: 'saved_auction',
        entityIds: window.localStorage.getItem('savedAuctionList')
          ? window.localStorage
              .getItem('savedAuctionList')
              .split(',')
              .map((auctionId) => ({ id: auctionId, propIdentityType: 95 }))
          : [],
      }
    } else {
      const cookies = new UCookies()
      body = {
        type: 'saved_property',
        entityIds: cookies.get('propertyStore')
          ? cookies.get('propertyStore')?.favourites?.map((propId) => {
              return {
                id: propId,
                propIdentityType: 95,
              }
            })
          : [],
      }
    }

    const res = await fetch(`${process.env.NEXT_PUBLIC_AUCTION_API_URL_V2}getAllSavedAuctionsProperties`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Cookie: `i18next=${lang}`,
        locale: lang,
      },
      body: JSON.stringify({ ...body }),
    })
    const resData = await res.json()

    const countType = {
      auction: 'savedAuctionCount',
      property: 'savedPropertyCount',
    }

    setCounts((prevCounts) => ({ ...prevCounts, [countType[type]]: resData?.data?.count }))
  }

  /**
   * getting auction and wallet help numbers
   */
  const getHelpNumbers = async () => {
    try {
      const postResponse = await fetchApi({
        lang,
        baseUrl: 'NEXT_PUBLIC_API_URL_V1',
        subUrl: `wallet-contact-us`,
        method: 'GET',
      })

      if (!postResponse?.data?.status) return

      setHelpNumbers({
        auctionNumber: postResponse?.data?.data[0].entity_exfield2,
      })
    } catch (error) {
      console.log('error')
    }
  }

  const getRequirementsCount = async () => {
    const res = await fetchApi({
      lang,
      baseUrl: 'NEXT_PUBLIC_GATEWAY_URL',
      subUrl: `requirement-manager/get-requirements`,
      method: 'GET',
    })

    if (res?.data?.status) {
      if (res?.data?.data?.requirement.length > 0) {
        setRequirementCount(res?.data?.data?.requirement?.length)
      }
    } else {
      console.log('error', res?.data?.message)
    }
  }
  const myfun = async () => {
    setMystate('updated state')
  }

  return (
    <DashboardContext.Provider
      value={{
        loadUser,
        userDetails,
        counts,
        setCounts,
        getSavedAuctionCount,
        getUserInfo,
        getSavedItemsCount,
        getUserFollowedAuctionCount,
        setUserDetails,
        helpNumbers,
        requirementCount,
        getHelpNumbers,
        localUserID,
        setLocalUserID,
        alertSound,
        myfun,
        mystate,
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}

export default DashboardProvider
