import React from 'react'

const IconPopupSuccess = ({customTick = false}) => {
  return customTick ? (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M17.1117 28.638C25.0199 28.638 31.4307 22.2271 31.4307 14.319C31.4307 6.41083 25.0199 0 17.1117 0C9.20355 0 2.79272 6.41083 2.79272 14.319C2.79272 18.7716 4.82506 22.7496 8.0127 25.3758C8.01827 25.3804 8.01331 25.3893 8.00648 25.387C8.00227 25.3855 7.9979 25.3887 7.9979 25.3931V29.2956C7.9979 30.7852 9.568 31.7521 10.8982 31.0815L15.3462 28.8393C15.678 28.6721 16.0496 28.604 16.4208 28.6216C16.6497 28.6325 16.8801 28.638 17.1117 28.638Z'
        fill='#005E38'
      />
      <path
        d='M14.3047 18.8892C13.9952 18.8892 13.7392 18.7885 13.5391 18.5884L9 14.104L10.5313 12.6001L14.3047 16.2915L21.4141 9.1001L22.9453 10.6313L15.043 18.5884C14.8428 18.7885 14.5967 18.8892 14.3047 18.8892Z'
        fill='white'
      />
    </svg>
  ) : (
    <svg width='42' height='42' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M24.0001 42.24C35.6644 42.24 45.1201 32.7843 45.1201 21.12C45.1201 9.45575 35.6644 0 24.0001 0C12.3359 0 2.88013 9.45575 2.88013 21.12C2.88013 27.6878 5.87802 33.5553 10.58 37.4289C10.5875 37.4351 10.5809 37.4471 10.5717 37.444C10.566 37.442 10.5601 37.4462 10.5601 37.4522V44.7521C10.5601 46.2417 12.1302 47.2085 13.4604 46.538L21.6384 42.4155C21.9702 42.2483 22.3418 42.1791 22.7126 42.2014C23.1385 42.227 23.5678 42.24 24.0001 42.24Z'
        fill='#005E38'
      />
      <path
        d='M25 29V31H13V29H25ZM25.586 9.68604L33.364 17.464L31.95 18.88L30.89 18.526L28.413 21L34.07 26.657L32.656 28.071L27 22.414L24.596 24.818L24.879 25.95L23.464 27.364L15.686 19.586L17.101 18.172L18.231 18.454L24.525 12.161L24.172 11.101L25.586 9.68604Z'
        fill='white'
      />
    </svg>
  )
}

export default IconPopupSuccess
